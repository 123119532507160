<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/tpr-delivery-price">
          <i class="el-icon-back mr10" />
        </router-link>
        TPR хүргэлтийн төлбөр
      </h3>
      <!-- <div class="action-section">
        <router-link to="/tpr-config-price-history" class="ml10">
          <el-button type="info" size="mini">Түүх харах</el-button>
        </router-link>
      </div> -->
    </div>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form v-if="tprDeliveryPrice">
          <div class="panel">
            <el-form-item label="Бүсийн нэр">
              <el-input disabled :value="`H` + this.hexaName"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
      <el-col :span="14">
        <div>
          <div class="panel">
            H3: {{ hexagonTable[zoomSize] }}
            <div class="partner-google-map">
              <gmap-map
                ref="map"
                :center="center"
                :zoom="zoomSize"
                :options="mapOptions"
                :draggable="true"
                style="width:100%;  height: 50vh;"
              >
                <gmap-marker
                  :position="position"
                  :clickable="true"
                  :draggable="true"
                />
                <gmap-polygon
                  v-for="(item, index) in getDefaultHexagonList()"
                  :key="index"
                  :paths="item.points"
                  :options="{
                    fillColor: hexagon_zone.allowed_list.includes(item.label)
                      ? '#000000'
                      : '#FFFFFF',
                    strokeOpacity: 0.2,
                    fillOpacity: getPolygonFillOpacity(
                      'allowed_list',
                      item.label
                    )
                      ? 0.5
                      : 0.1
                  }"
                  :editable="item.editable"
                >
                </gmap-polygon>
              </gmap-map>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </el-col>
      <el-col :span="24" class="mt10">
        <div class="panel">
          <el-tabs v-model="isTab" @tab-click="handleClickTab">
            <el-tab-pane
              v-for="(tab, indexTab) in tabData"
              :key="indexTab"
              :label="tab.label"
              :name="tab.name"
            >
              <header v-if="isTab === 'settings'">
                Тохиргоо нэмэх
                <el-button class="ml20" size="mini" @click="addRow()"
                  ><i class="el-icon-plus"></i
                ></el-button>
              </header>
              <el-table
                style="width: 100%"
                :data.sync="tprDeliveryPrice"
                size="mini"
                class="mt10"
              >
                <el-table-column label="TPR MIN" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.tplh_min"
                    ></el-input>
                    <span v-else>{{ scope.row.tplh_min }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="TPR MAX" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.tplh_max"
                    ></el-input>
                    <span v-else>{{ scope.row.tplh_max }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Тайлбар"
                  width="150px"
                  prop="desc"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-input
                      type="textarea"
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.desc"
                    ></el-input>
                    <span v-else>{{ scope.row.desc }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Үнэ" prop="base_price" align="center">
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.base_price"
                    ></el-input>
                    <span v-else>{{ scope.row.base_price }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Ачаа тээвэр (үнэ)" align="center">
                  <el-table-column
                    label="2-3 км"
                    prop="km_price"
                    width="95"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-if="scope.row.isEdit"
                        v-model="scope.row.km_price"
                      ></el-input>
                      <span v-else>{{ scope.row.km_price }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="3-с дээш км"
                    prop="price_3_km"
                    width="95"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-if="scope.row.isEdit"
                        v-model="scope.row.price_3_km"
                      ></el-input>
                      <span v-else>{{ scope.row.price_3_km }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="Үндсэн жолооч (үнэ)" align="center">
                  <el-table-column
                    label="2-3 км"
                    prop="company_driver_km_price"
                    width="95"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-if="scope.row.isEdit"
                        v-model="scope.row.company_driver_km_price"
                      ></el-input>
                      <span v-else>{{
                        scope.row.company_driver_km_price
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="3-с дээш км"
                    prop="company_driver_3_km_price"
                    width="95"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-if="scope.row.isEdit"
                        v-model="scope.row.company_driver_3_km_price"
                      ></el-input>
                      <span v-else>{{
                        scope.row.company_driver_3_km_price
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column
                  label="Нэмэгдэх минут"
                  prop="delivery_time"
                  width="80px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-input
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.delivery_time"
                    ></el-input>
                    <span v-else>{{ scope.row.delivery_time }}</span>
                  </template></el-table-column
                >
                <el-table-column label="Төлөв" align="center" width="130px">
                  <template slot-scope="scope">
                    <div v-if="scope.row.isEdit">
                      <el-select
                        v-model="scope.row.status"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="(status, index) in optionsStatus"
                          :key="index"
                          :label="status.label"
                          :value="status.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <el-tag
                        size="mini"
                        v-if="scope.row.status === true"
                        type="primary"
                        >Идэвхтэй</el-tag
                      >
                      <el-tag v-else size="mini" type="info">Идэвхгүй</el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Эхлэх дуусах өдөр"
                  width="160px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.start_date"
                      placeholder="Эхлэх өдөр"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <el-date-picker
                      size="mini"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.end_date"
                      placeholder="Дуусах өдөр"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <span
                      v-if="
                        scope.row.start_date &&
                          scope.row.end_date &&
                          !scope.row.isEdit
                      "
                      >{{ generateDate(scope.row.start_date) }} -
                      {{ generateDate(scope.row.end_date) }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="Эхлэх дуусах цаг"
                  width="160px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-time-picker
                      size="mini"
                      value-format="HH:mm:ss"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.start_time"
                      placeholder="Эхлэх хугацаа"
                    >
                    </el-time-picker>
                    <el-time-picker
                      size="mini"
                      value-format="HH:mm:ss"
                      v-if="scope.row.isEdit"
                      v-model="scope.row.end_time"
                      placeholder="Эхлэх хугацаа"
                    >
                    </el-time-picker>
                    <span
                      v-if="
                        scope.row.start_time &&
                          scope.row.end_time &&
                          !scope.row.isEdit
                      "
                      >{{ scope.row.start_time }} -
                      {{ scope.row.end_time }}</span
                    >
                  </template></el-table-column
                >
                <el-table-column label="7 хоног" width="190px">
                  <template slot-scope="scope">
                    <el-select
                      v-if="scope.row.isEdit"
                      v-model="scope.row.weeking_days"
                      multiple
                      size="mini"
                      placeholder="Өдөр сонгох"
                    >
                      <el-option
                        v-for="(day, indexDay) in optionsDay"
                        :key="indexDay"
                        :label="day.label"
                        :value="day.value"
                      >
                      </el-option>
                    </el-select>
                    <span v-else>
                      <el-tag
                        type="warning"
                        v-for="(day, index) in scope.row.weeking_days"
                        :key="index"
                        >{{ day }}</el-tag
                      ></span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="Илгээх мэссеж" width="120px">
                  <template slot-scope="scope">
                    <el-popover
                      placement="top-start"
                      title="Илгээх мэссеж"
                      width="300"
                      trigger="click"
                    >
                      <el-input
                        v-model="scope.row.msg_text"
                        type="textarea"
                        :rows="3"
                        placeholder="Илгээх тайлбараа энд бичнэ үү..."
                      ></el-input>
                      <el-input
                        slot="reference"
                        type="textarea"
                        :rows="2"
                        placeholder="Апп дээр харагдах текст"
                        v-model="scope.row.msg_text"
                        :disabled="!scope.row.isEdit"
                      ></el-input>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  label="Үйлдэл"
                  width="110"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.isEdit">
                      <el-button
                        size="mini"
                        type="danger"
                        @click="onClickCancel(scope.row, scope.$index)"
                        >{{
                          Object.prototype.hasOwnProperty.call(scope.row, "id")
                            ? "Болих"
                            : "Устгах"
                        }}
                      </el-button>
                    </div>
                    <div v-if="scope.row.isEdit === true" class="mt10">
                      <el-button
                        size="mini"
                        type="success"
                        @click="handleClick(scope.row)"
                        >Хадгалах</el-button
                      >
                    </div>
                    <div v-if="isTab === 'history'">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="onClickHistory(scope.row.id)"
                        >Дэлгэрэнгүй</el-button
                      >
                    </div>
                    <div v-else>
                      <el-button size="mini" @click="scope.row.isEdit = true">
                        <i class="el-icon-edit"></i>
                      </el-button>
                      <el-button
                        v-if="scope.row.is_default !== 1"
                        size="mini"
                        type="danger"
                        style="margin-left: 5px"
                        @click="deleteTprDeliveryPriceDetail(scope.row.id)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Түүх дэлгэрэнгүй харах"
      center
      v-if="historyData"
      :visible.sync="visibleHistoryDialog"
      width="60%"
    >
      <el-row>
        <el-table size="mini" :data="historyData">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-row :gutter="20">
                <el-col :span="scope.row.type === 'create' ? 24 : 12">
                  <el-row :gutter="20">
                    <el-card class="box-card">
                      <el-form :model="scope.row" :disabled="true">
                        <el-row>
                          <span
                            ><strong>Хуучин</strong> -
                            {{ scope.row.new.created_at }}</span
                          ><el-tag
                            size="mini"
                            type="primary"
                            class="ml10"
                            v-if="scope.row.new.status"
                            >Идэвхтэй</el-tag
                          >
                          <el-tag size="mini" type="info" class="ml10" v-else
                            >Идэвхгүй</el-tag
                          >
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="Үндсэн үнэ">
                              <el-input
                                v-model="scope.row.new.base_price"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item label="Нэмэлт үнэ">
                              <el-input
                                v-model="scope.row.new.add_price"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="Км үнэ">
                              <el-input
                                v-model="scope.row.new.km_price"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item label="Нэмэлт хугацаа">
                              <el-input
                                v-model="scope.row.new.delivery_time"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="TPR min">
                              <el-input
                                v-model="scope.row.new.tplh_min"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item label="TPR max">
                              <el-input
                                v-model="scope.row.new.tplh_max"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="Эхлэх өдөр">
                              <el-input
                                v-model="scope.row.new.start_date"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item label="Дуусах өдөр">
                              <el-input
                                v-model="scope.row.new.end_date"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="Эхлэх цаг">
                              <el-input
                                v-model="scope.row.new.start_time"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item label="Дуусах цаг">
                              <el-input
                                v-model="scope.row.new.end_time"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="7 хоног">
                              <el-select
                                multiple
                                v-model="scope.row.new.weeking_days"
                              >
                                <el-option
                                  size="mini"
                                  v-for="(day, indexDay) in scope.row.new
                                    .weeking_days"
                                  :label="day"
                                  :key="indexDay"
                                  :value="day"
                                ></el-option
                              ></el-select> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item label="Тайлбар">
                              <el-input
                                type="textarea"
                                :rows="2"
                                v-model="scope.row.new.desc"
                              ></el-input> </el-form-item
                          ></el-col>
                        </el-row>
                      </el-form>
                    </el-card> </el-row
                ></el-col>
                <el-col v-if="scope.row.type === 'update'" :span="12">
                  <el-row :gutter="20">
                    <el-card class="box-card">
                      <el-form :model="scope.row" :disabled="true">
                        <el-row
                          ><span
                            ><strong>Шинэ</strong> -
                            {{ scope.row.old.created_at }}</span
                          ><el-tag
                            size="mini"
                            type="primary"
                            class="ml10"
                            v-if="scope.row.old.status"
                            >Идэвхтэй</el-tag
                          >
                          <el-tag size="mini" type="info" class="ml10" v-else
                            >Идэвхгүй</el-tag
                          >
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item
                              label="Үндсэн үнэ"
                              :class="
                                scope.row.new.base_price !==
                                scope.row.old.base_price
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.base_price"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item
                              label="Нэмэлт үнэ"
                              :class="
                                scope.row.new.add_price !==
                                scope.row.old.add_price
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.add_price"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item
                              label="Км үнэ"
                              :class="
                                scope.row.new.km_price !==
                                scope.row.old.km_price
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.km_price"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item
                              label="Нэмэлт хугацаа"
                              :class="
                                scope.row.new.delivery_time !==
                                scope.row.old.delivery_time
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.delivery_time"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item
                              label="TPR min"
                              :class="
                                scope.row.new.tplh_min !==
                                scope.row.old.tplh_min
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.tplh_min"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item
                              label="TPR max"
                              :class="
                                scope.row.new.tplh_max !==
                                scope.row.old.tplh_max
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.tplh_max"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item
                              label="Эхлэх өдөр"
                              :class="
                                scope.row.new.start_date !==
                                scope.row.old.start_date
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.start_date"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item
                              label="Дуусах өдөр"
                              :class="
                                scope.row.new.end_date !==
                                scope.row.old.end_date
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.end_date"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item
                              label="Эхлэх цаг"
                              :class="
                                scope.row.new.start_time !==
                                scope.row.old.start_time
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.start_time"
                              /> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item
                              label="Дуусах цаг"
                              :class="
                                scope.row.new.end_time !==
                                scope.row.old.end_time
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                v-model="scope.row.old.end_time"
                              /> </el-form-item
                          ></el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="7 хоног">
                              <el-select
                                multiple
                                v-model="scope.row.old.weeking_days"
                              >
                                <el-option
                                  v-for="(day, indexDay) in scope.row.old
                                    .weeking_days"
                                  :key="indexDay"
                                  :label="day"
                                  :value="day"
                                >
                                </el-option>
                              </el-select> </el-form-item
                          ></el-col>
                          <el-col :span="12">
                            <el-form-item
                              label="Тайлбар"
                              :class="
                                scope.row.new.desc !== scope.row.old.desc
                                  ? 'labelColor'
                                  : ''
                              "
                            >
                              <el-input
                                type="textarea"
                                :rows="2"
                                v-model="scope.row.old.desc"
                              ></el-input> </el-form-item
                          ></el-col>
                        </el-row>
                      </el-form>
                    </el-card> </el-row
                ></el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="Лог нэр" prop="log_name"></el-table-column>
          <el-table-column label="ID" prop="title"></el-table-column>
          <el-table-column label="Төрөл" prop="type"></el-table-column>
          <el-table-column label="Хэрэглэгч" prop="user_name"></el-table-column>
          <el-table-column label="Хугацаа" prop="created_at"></el-table-column>
          <el-table-column label="Тайлбар" prop="desc"></el-table-column>
        </el-table>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button
          size="mini"
          class="mt10"
          type="danger"
          @click="visibleHistoryDialog = false"
          >Хаах</el-button
        ></el-row
      >
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { h3ToChildren, h3ToGeoBoundary } from "h3-js";
export default {
  name: "tprDeliveryPriceDetail",

  mounted() {
    this.hexaName = this.$route.query.name;
    this.getTprDeliveryPriceDetail(
      this.$route.query.name,
      this.$route.query.default
    );
    this.getDefaultHexagonList();
  },

  data() {
    return {
      historyData: null,
      visibleHistoryDialog: false,
      isTab: "settings",
      UBHexagonList: [
        "86255a38fffffff",
        "86255a217ffffff",
        "86255a39fffffff",
        "86255a2a7ffffff",
        "86255a3afffffff",
        "86255a237ffffff",
        "86255a387ffffff"
      ],
      zoomSize: 11,
      optionsStatus: [
        {
          value: true,
          label: "Идэвхтэй"
        },
        {
          value: false,
          label: "Идэвхгүй"
        }
      ],
      optionsDay: this.setOptionsDay(),
      hexaName: "",
      tprDeliveryPrice: [],
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      hexagon_zone: {
        allowed_list: [],
        blocked_list: []
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      hexagonTable: {
        "20": 14,
        "19": 13,
        "18": 12,
        "17": 11,
        "16": 10,
        "15": 9,
        "14": 8,
        "13": 7,
        "12": 6,
        "11": 5,
        "10": 4,
        "9": 3,
        "8": 2,
        "7": 1
      },
      position: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      tabData: [
        {
          label: "Тохиргоонууд",
          name: "settings"
        },
        {
          label: "Түүх харах",
          name: "history"
        }
      ]
    };
  },

  methods: {
    handleClickTab(data) {
      this.isTab = data.name;
    },
    getDefaultHexagonList() {
      const currentHexagonSize = parseInt(this.hexaName);
      const hexagonList = [];
      this.UBHexagonList.forEach(el => {
        let hexagons = h3ToChildren(el, currentHexagonSize);
        hexagonList.push(...hexagons);
      });
      return this.generatePolygonsFromHexagonList(hexagonList);
    },

    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    },

    getPolygonFillOpacity(type) {
      if (type === "allowed_list") {
        return true;
      }
      return false;
    },

    handleClick(data) {
      if (Object.prototype.hasOwnProperty.call(data, "id")) {
        this.updateTprDeliveryPriceDetail(data);
      } else {
        this.handleClickAdd(data);
      }
    },

    setOptionsDay() {
      return [
        {
          value: "Mon",
          label: "Даваа"
        },
        {
          value: "Tue",
          label: "Мягмар"
        },
        {
          value: "Wed",
          label: "Лхагва"
        },
        {
          value: "Thu",
          label: "Пүрэв"
        },
        {
          value: "Fri",
          label: "Баасан"
        },
        {
          value: "Sat",
          label: "Бямба"
        },
        {
          value: "Sun",
          label: "Ням"
        }
      ];
    },

    onClickCancel(data, index) {
      if (Object.prototype.hasOwnProperty.call(data, "id")) {
        data.isEdit = false;
      } else {
        this.tprDeliveryPrice.splice(index, 1);
      }
    },

    generateDate(date) {
      if (date) {
        let tempDate = "";
        tempDate = date.split(" ");
        return tempDate[0];
      }
    },

    getTprDeliveryPriceDetail(hexaName, isDefault) {
      this.tprDeliveryPrice = [];
      this.openFullScreenLoader(true);
      service.getTprDeliveryPriceDetail(hexaName, isDefault).then(response => {
        if (response.data.status === "success") {
          this.tprDeliveryPrice = response.data.data.map(el => {
            return {
              isEdit: false,
              ...el
            };
          });
          this.openFullScreenLoader(false);
        } else {
          this.openFullScreenLoader(false);
        }
      });
    },

    onClickHistory(id) {
      this.openFullScreenLoader(true);
      service
        .getTprDeliveryPriceHistory(id)
        .then(response => {
          if (response.data.status === "success") {
            this.historyData = response.data.history;
            this.visibleHistoryDialog = true;
            this.openFullScreenLoader(false);
          } else {
            this.openFullScreenLoader(false);
          }
        })
        .catch(() => {
          this.openFullScreenLoader(false);
        });
    },

    handleClickAdd(data) {
      this.openFullScreenLoader(true);
      data.add_price = parseInt(data.add_price);
      data.tplh_min = parseInt(data.tplh_min);
      data.tplh_max = parseInt(data.tplh_max);
      data.base_price = parseInt(data.base_price);
      data.delivery_time = parseInt(data.delivery_time);
      data.km_price = parseInt(data.km_price);
      data.price_3_km = parseInt(data.price_3_km);
      data.company_driver_3_km_price = parseInt(data.company_driver_3_km_price);
      data.company_driver_km_price = parseInt(data.company_driver_km_price);
      data.hexa_name = parseInt(data.hexa_name);
      service
        .createTprDeliveryPriceDetail(data)
        .then(response => {
          if (response.data.status === "success") {
            this.$notify({
              title: "Амжилттай",
              message: response.data.message,
              type: "success"
            });
            this.openFullScreenLoader(false);
            this.getTprDeliveryPriceDetail(
              this.$route.query.name,
              this.$route.query.default
            );
          } else {
            this.$notify({
              title: "Амжилтгүй",
              message: response.data.message,
              type: "warning"
            });
            this.openFullScreenLoader(false);
          }
        })
        .catch(() => {
          this.openFullScreenLoader(false);
        });
    },

    updateTprDeliveryPriceDetail(data) {
      data.isEdit = true;
      this.$forceUpdate();
      this.openFullScreenLoader(true);
      data.add_price = parseInt(data.add_price);
      data.tplh_min = parseInt(data.tplh_min);
      data.tplh_max = parseInt(data.tplh_max);
      data.base_price = parseInt(data.base_price);
      data.delivery_time = parseInt(data.delivery_time);
      data.km_price = parseInt(data.km_price);
      data.price_3_km = parseInt(data.price_3_km);
      data.company_driver_3_km_price = parseInt(data.company_driver_3_km_price);
      data.company_driver_km_price = parseInt(data.company_driver_km_price);
      data.hexa_name = parseInt(data.hexa_name);
      service
        .updateTprDeliveryPriceDetail(data)
        .then(response => {
          if (response.data.status === "success") {
            this.$notify({
              title: "Амжилттай",
              message: response.data.message,
              type: "success"
            });
            this.openFullScreenLoader(false);
            this.getTprDeliveryPriceDetail(data.hexa_name, data.is_default);
          } else {
            this.$notify({
              title: "Амжилтгүй",
              message: response.data.message,
              type: "warning"
            });
            this.openFullScreenLoader(false);
          }
        })
        .catch(() => {
          this.openFullScreenLoader(false);
        });
    },

    deleteTprDeliveryPriceDetail(id) {
      this.openFullScreenLoader(true);
      this.$confirm("Энэ TPR жагсаалт устгахдаа итгэлтэй байна уу?", "Анхаар", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteTprDeliveryPriceDetail(id).then(response => {
            if (response.data.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: response.data.message,
                type: "success"
              });
              this.openFullScreenLoader(false);
              this.getTprDeliveryPriceDetail(
                this.$route.query.name,
                this.$route.query.default
              );
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message: response.data.message,
                type: "warning"
              });
              this.openFullScreenLoader(false);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
          this.openFullScreenLoader(false);
        });
    },

    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },

    addRow() {
      this.tprDeliveryPrice.unshift({
        hexa_name: this.$route.query.name,
        add_price: null,
        base_price: null,
        delivery_time: null,
        desc: "",
        end_date: null,
        end_time: null,
        km_price: null,
        start_date: null,
        start_time: null,
        status: true,
        tplh_max: null,
        tplh_min: null,
        weeking_days: [],
        isEdit: true
      });
    }
  }
};
</script>

<style>
.labelColor .el-form-item__label {
  color: red;
}
.el-form-item {
  margin-bottom: 0px;
}
</style>
